<section fxLayout fxLayout.lt-sm="column"  fxLayoutGap="16px">
  <section fxFlex fxLayoutAlign.lt-sm="center">
    <h1 mat-dialog-title style="margin: 16px">Covid-19 Announcement</h1>
  </section>
  <section fxFlex fxLayout fxLayoutAlign.lt-sm="center" fxLayoutAlign.gt-sm="end">
    <a target="_blank" href="https://sacoronavirus.co.za/" style="text-decoration: none; outline:none"> <img style="max-width: 300px; min-width: 150px" src="../assets/sacoronavirus.png"></a>
  </section>


</section>


<div mat-dialog-content style="padding: 0px 30px 30px 30px">
  <p class="detail-card-text">As psychology is considered an essential service, the practice is still open and offering online and
    in-person* psychotherapy services. Please feel free to contact the practice so we can explore how we
    can assist you during this time.</p>

  <p class="detail-card-text">*Please note that in-person sessions are assessed on a case-by-case basis and can only
    be offered for teenagers and adults.</p>
</div>
<section class="cardShadow" style="max-width: 300px; padding: 10px; cursor: pointer;" (click)="onBlog()">
  Click here to check out my blog for Covid-19 tips and resources
</section>

<div mat-dialog-actions align="end" >
  <button mat-button (click)="onDialogClose()">Close</button>
</div>
