import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogStateService {
 private hasDisplayed = false;
  constructor() { }

  dialogEvent() {
      this.hasDisplayed = true;
  }

  getState() {
    return this.hasDisplayed;
  }
}
