
<mat-nav-list>
  <a mat-list-item fxLayoutAlign="center" routerLink="/about-me" (click)="onSelect()">
    <span class="nav-caption">About Me</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item fxLayoutAlign="center" routerLink="/about-psychotherapy" (click)="onSelect()">
    <span class="nav-caption">About Psychotherapy</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item fxLayoutAlign="center" routerLink="/how-can-i-help" (click)="onSelect()" >
    <span class="nav-caption listHead">How Can I Help</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item fxLayoutAlign="center" routerLink="/where-to-find-me" (click)="onSelect()">
    <span class="nav-caption">Where To Find Me</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item fxLayoutAlign="center" routerLink="/blog" (click)="onSelect()">
    <span class="nav-caption">Blog</span>
  </a>
  <mat-divider></mat-divider>
</mat-nav-list>


