<section id="about-psychotherapy-wrapper" >
  <section fxLayout id="row2" fxLayoutAlign="center">
    <mat-card id="detail-card">
      <img mat-card-image src="../assets/dr-defferary--what-is-psychotherapy.jpg" alt="Dr Tanya Defferary">
        <mat-card-title>
          What is Psychotherapy
        </mat-card-title>
        <mat-card-content>
          <p class="detail-card-text">
            While I was studying for my Master’s degree, the whole class was encouraged to consult with a therapist so
            we could have the experience of a personal session. I remember thinking, “ugh, why do I need to do that?”
            After giving it some thought, I realised that no matter who we are, no matter what we have been through,
            everyone can benefit from speaking to someone who has professional training.
          </p>
          <p class="detail-card-text">
            To speak to someone who is objective, who can impartially listen to our story, to help us gain new insights
            for ourselves and our situation, is amazing. It was a humbling and nerve-wracking experience to sit on the
            couch, opposite a person whom you do not know, and be expected to share some of your innermost secrets,
            aspects of yourself which you may have never shared with anyone before.
          </p>
          <p class="detail-card-text">
            Going through the process of choosing a therapist can be an overwhelming task as you need to find someone
            whose therapeutic style matches your personality. Your therapist is also someone with whom you will be
            spending a great deal of one-on-one time, so it needs to be someone whom you can learn to trust and with
            whom you can get on. It can be helpful to ask friends and colleagues for referrals as they will be able to
            give you an understanding of the personality of the therapist whom you will decide to consult. If you have
            no-one to ask, make some phone calls. You will soon determine whether the therapist is someone you could
            resonate with.
          </p>
          <p class="detail-card-text">
            When you have your first session, don’t be afraid to ask your therapist questions about the therapeutic
            approach he/she uses and what you can expect from the therapy. If you give yourself the time and space,
            psychotherapy can be an insightful experience. Remember, the change which happens during therapy is a
            process. This means that it is not something which happens immediately. It takes time, courage and effort
            and when you make a commitment and have found the therapist which suits you, it can lead to positive growth
            and personal transformation.
          </p>
          <p class="detail-card-text">
            Good luck on your therapeutic journey!
          </p>
        </mat-card-content>
    </mat-card>
  </section>
</section>
