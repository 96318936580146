import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Observer, Subscription, interval } from 'rxjs';
import { DialogStateService } from 'src/app/dialog-state.service';

export interface IMedia {
  title: string;
  src: string;
  type: string;
}

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private dialog: MatDialog, private dialogState: DialogStateService) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    if (!this.dialogState.getState()) {
      setTimeout(() => this.openDialog(), 100);
    }
  }


  ngOnDestroy(): void {
  }

  openDialog() {
      // const dialogRef = this.dialog.open(CovidDialogComponent, {
      //   minWidth: '400px',
      // });
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      // });
    }


}
