<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">

  <mat-toolbar class="example-toolbar">
    <div fxHide.gt-sm>
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div id="toolbar" fxFlex fxLayoutAlign="center" fxLayoutGap="20px" fxHide.lt-md>
      <button mat-button routerLink="/about-me">About Me</button>
      <button mat-button routerLink="/about-psychotherapy">About Psychotherapy</button>
      <button mat-button routerLink="/how-can-i-help">How Can I Help</button>
      <button mat-button routerLink="/where-to-find-me">Where To Find Me</button>
      <button mat-button routerLink="/blog">Blog</button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 50 : 0">
    <mat-sidenav #snav [mode]="'over'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="50">
      <mat-nav-list>
        <a mat-list-item fxLayoutAlign="center" routerLink="/about-me" (click)="snav.toggle()">
          <span class="nav-caption">About Me</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item fxLayoutAlign="center" routerLink="/about-psychotherapy" (click)="snav.toggle()">
          <span class="nav-caption">About Psychotherapy</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item fxLayoutAlign="center" routerLink="/how-can-i-help" (click)="snav.toggle()" >
          <span class="nav-caption listHead">How Can I Help</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item fxLayoutAlign="center" routerLink="/where-to-find-me" (click)="snav.toggle()">
          <span class="nav-caption">Where To Find Me</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item fxLayoutAlign="center" routerLink="/blog" (click)="snav.toggle()">
          <span class="nav-caption">Blog</span>
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
