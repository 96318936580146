import {Component, HostListener, OnInit, ViewChild} from '@angular/core';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  lat = -33.950171;
  lng = 25.536019;
  notMobile;


  constructor() {
    this.onResize();
  }

  ngOnInit() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 900) {
      this.notMobile = false;
    } else {
      this.notMobile = true;
    }
  }

  onMapClick(event) {
    window.open('https://maps.google.com/?q=54,Stella Londt Drive,Sunridge Park, Port Elizabeth');
  }
}


