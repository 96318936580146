<section id="about-psychotherapy-wrapper" >
  <section fxLayout fxLayoutAlign="center">
    <mat-card id="detail-card">
      <img mat-card-image src="../assets/dr-defferary-counselling2.jpg" alt="Dr Tanya Defferary">
      <mat-card-title>
        Counselling Services
      </mat-card-title>
      <mat-card-content fxLayout="column" fxLayoutGap="16px">
        <section class="detail-card-text">
          Working from a Contextual Therapeutic basis (an intergenerational approach that focuses on
          the individual as well as the family), allows me to consult with individuals and families from
          all walks of life. This approach allows me to tailor my sessions to best fit the needs of my
          clients, by bringing in aspects of Transactional Analysis (TA), Gestalt Therapy, Cognitive
          Behaviour Therapy and Person Centred Therapy (PCT), as well as mindfulness techniques.
        </section>
        <section class="detail-card-text">
          Psychotherapy services offered:
        <section class="detail-card-text">
          -          Individual sessions  (adults and children)
        </section>
          -          Family sessions
        <section class="detail-card-text">
          -          Online Teletherapy
        </section>

        </section>



        <section fxLayout="row" fxLayoutGap="16px">
          <section fxLayoutAlign="start center">
            <img src="../assets/zoomIcon.jpg" alt="Zoom" >
          </section>

          <section class="detail-card-text" fxLayoutAlign="start center">Sessions can also be offered over Zoom.</section>
        </section>
      </mat-card-content>
    </mat-card>
  </section>
</section>
