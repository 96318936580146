import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AboutMeComponent} from './about-me/about-me.component';
import {AboutPsychotherapyComponent} from './about-psychotherapy/about-psychotherapy.component';
import {ServicesComponent} from './services/services/services.component';
import {NgModule} from '@angular/core';
import {ContactComponent} from './contact/contact.component';
import {PsychotherapyComponent} from './services/psychotherapy/psychotherapy.component';
import {WorkshopsComponent} from './services/workshops/workshops.component';
import { BlogComponent } from 'src/app/blog/blog.component';

const routes: Routes = [
  { path: "", component: AboutMeComponent },
  { path: "about-psychotherapy", component: AboutPsychotherapyComponent },
  { path: "about-me", component: AboutMeComponent },
  { path: "about-psychotherapy", component: AboutPsychotherapyComponent },
  { path: "how-can-i-help", component: ServicesComponent },
  { path: "where-to-find-me", component: ContactComponent },
  { path: "counselling", component: PsychotherapyComponent },
  { path: "workshops", component: WorkshopsComponent },
  { path: "blog", component: BlogComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
