<section fxLayout="column" fxLayoutAlign="center center">
  <section fxLayout fxLayoutAlign="center">
    <mat-card id="detail-card">
      <img mat-card-image src="../assets/dr-defferary-workshops2.jpg" alt="Dr Tanya Defferary">
      <mat-card-title>
        Workshop Development and Facilitation
      </mat-card-title>
      <mat-card-content>
        <p class="detail-card-text">
          Dr Tanya Defferary has already successfully offered a host of workshop development and facilitation services across South Africa.
        </p>
        <p class="detail-card-text">
          These workshops can be tailor-made to meet the unique needs of your organisation.
          Should you wish to find out more email: info@drdefferary.co.za
        </p>
      </mat-card-content>
    </mat-card>
  </section>

  <section fxLayout="row" fxLayoutAlign="center" >
    <mat-card class="headings"><mat-card-title>Look out for future events</mat-card-title></mat-card>
  </section>

  <section fxLayout="row" fxLayoutAlign="center" >
    <mat-card class="headings"><mat-card-subtitle>Previous Events</mat-card-subtitle></mat-card>
  </section>

  <section fxLayout="row warp" fxLayoutAlign="center" id="events">

    <mat-card class="event">
      <mat-card-title>Fear of Failure Workshop</mat-card-title>
      <img mat-card-image src="../assets/dr-defferary-fear-of-failure-.jpg" alt="Fear of Failure">
      <mat-card-title>Dr Ard Nieuwenbroek</mat-card-title>
      <mat-card-content>
        Join international guest-speaker and Contextual Therapist, Dr Ard Nieuwenbroek, as he explores the
        complexities of fear of failure with us, based on his new book ‘Het Gehiem Achter Faalangst’
        (The Secret Behind Fear of Failure). For two evenings only, Ard will explain how we can assist children who
        experience fear of failure, based on the Contextual Therapeutic Perspective.
      </mat-card-content>
      <mat-card-subtitle>
        Dates:  26, 27  February 2018 Port Elizabeth
      </mat-card-subtitle>
    </mat-card>

    <mat-card class="event">
      <mat-card-title>Dangerous Personalities</mat-card-title>
      <img mat-card-image src="../assets/dr-defferary-narcissism.jpg" alt="Fear of Failure">
      <mat-card-title>Narcissism Talk</mat-card-title>
      <mat-card-content>
        Join Behaviour Specialist, Ian Ferreira and Counsellling Psychologist, Dr Tanya Defferary for a first-of–its-kind
        workshop in Port Elizabeth. During this two hour talk these two experts will take you on a journey to explore
        the intricacies of Narcissism. Using their knowledge, gathered from the fields of Behaviour Analysis and
        Contextual Therapy, they will explain what it is like to be in a relationship with a narcissist,
        how to start noticing the ‘red-flags’ and why we tend to get stuck in these kinds of relationships,
        with our partners, employers or friends.
      </mat-card-content>
      <mat-card-subtitle>
        Dates: 20 November 2017
        21 November 2017
        28 November 2017
      </mat-card-subtitle>
    </mat-card>

  </section>
</section>
