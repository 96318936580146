<div fxLayout fxLayoutAlign="center center">
  <div class="container" fxFlex>
    <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center" >

      <div class="card">
        <section class="mat-h1" style="padding: 10px;">
          Cognitive Reframing during Covid-19
        </section>
        <div class="video-container">
          <iframe width="560" height="315"
                  src="https://www.youtube.com/embed/tEg8rURjc2s?rel=0"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        <p class="mat-h4" style="padding: 10px;">
          In this video I explore the concept of cognitive reframing,
          by explaining some of the different types of cognitive distortions, as well as the 3 main steps to cognitive reframing.
        </p>
      </div>

      <div class="card">
        <section class="mat-h1" style="padding: 10px;">
          Flower of Change - helping children cope with loss and change due to Covid-19
        </section>
        <div class="video-container">
          <iframe width="560" height="315"
                  src="https://www.youtube.com/embed/ZK6IRQNf4E8?rel=0"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        <p class="mat-h4" style="padding: 10px;">
          I have noticed a trend with children at the moment where they are struggling to cope with the loss of 'normal' life due to Covid-19, for example seeing friends, playing outside, and going to school.
          I came up with this activity to firstly facilitate a discussion to acknowledge what the child is missing at the moment, as well as to open a dialogue of the uncertainty of what the future may bring.
          When I first conceptualised the activity it was for children aged between 6 - 13, however I have even used this with some of my older teen clients.
          You can really adapt it quite well for the developmental age of the child. Enjoy.
        </p>
        <p class="mat-h4" style="padding: 10px;">
          Download the activity <a href="../assets/The_Flower_of_Change.pdf" download> here</a>
        </p>
      </div>

      <div class="card">
        <section class="mat-h1" style="padding: 10px;">
          The Worry Jar - Containing your anxiety and stress
        </section>
        <div class="video-container">
          <iframe width="560" height="315"
                  src="https://www.youtube.com/embed/x69ymQ8JOTw?rel=0"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        <p class="mat-h4" style="padding: 10px;">
          The worry jar is a wonderful activity, for both children and adults, to help identify and contain
          anxiety and stress. It can help us to lessen our anxiety and become more mindful
          about the things we worry about.
        </p>
      </div>

      <div class="card">
        <section class="mat-h1" style="padding: 10px;">
          Mindfulness Activity - The Secret Garden
        </section>
        <div class="video-container">
          <iframe width="560" height="315"
                  src="https://www.youtube.com/embed/8iyPI-3oRDQ?rel=0"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        <p class="mat-h4" style="padding: 10px;">
          Mindfulness has been found to help people center themselves and focus.
          In this video I take you on a guided mindfulness activity to a
          secret garden where you can hopefully find some inner calm and stillness.

        </p>
      </div>

      <div class="card">
          <section class="mat-h1" style="padding: 10px;">
            It's OK to not be OK - Covid19
          </section>
          <div class="video-container">
            <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/d7EHBReqh8E?rel=0"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
          <p class="mat-h4" style="padding: 10px;">
            I explore some of the behaviours and ways of thinking we should be aware of during the lockdown.
          </p>
      </div>

    <div class="card">
      <section class="mat-h1" style="padding: 10px;">
        Covid19 Lockdown - Where are you at?
      </section>
      <div class="video-container">
        <iframe width="560" height="315"
                src="https://www.youtube.com/embed/j3o1q7pCiZ0?rel=0"
                frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <p class="mat-h4" style="padding: 10px;">
        This video explores the importance of acknowledging that the experiences of lockdown are unique for each individual.
      </p>
    </div>
  </div>
</div>





