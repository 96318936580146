<section id="service-wrapper">
    <section id="service-cards-wrapper" fxLayout="wrap" fxLayoutAlign="center">
      <a routerLink="/counselling">
        <mat-card>
          <img mat-card-image src="../assets/dr-defferary-counselling.jpg" alt="Dr Tanya Defferary">
          <mat-card-title>
            Counselling
          </mat-card-title>
          <mat-card-subtitle>Teens, Adults, Families</mat-card-subtitle>
        </mat-card>
      </a>
      <a routerLink="/workshops">
      <mat-card mat-color >
          <img mat-card-image src="../assets/dr-defferary-workshops.jpg" alt="Dr Tanya Defferary">
          <mat-card-title>
            Workshops
          </mat-card-title>
          <mat-card-subtitle>Corporate, Schools, Private </mat-card-subtitle>
        </mat-card>
      </a>
    </section>
</section>



