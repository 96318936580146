<section id="contact-wrapper" >
  <section id="contact-column" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">

    <section class="map-text-card">
      <iframe _ngcontent-c13="" allow="autoplay; encrypted-media" allowfullscreen="" frameborder="0" height="500" src="https://www.youtube.com/embed/zaBKIH5Lidk?rel=0&amp;autoplay=1" width="100%"></iframe>
    </section>

    <section id="map-card">
      <section  fxFlex="100">
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          style="border:0"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDxig7nF_TEpr3R7EC41h0KuaO5L0CHh3Y&amp;q=place_id:ChIJ-VhH1hHReh4RG6m-XaNOrYg&amp;zoom=14">
        </iframe>
      </section>
    </section>

      <mat-card fxLayout="column" class="map-text-card">
        <section fxLayout="column" fxLayoutAlign="start center">
          <mat-card-title style="text-align: center">
            115 Sonop Street, Theescombe
          </mat-card-title>
          <mat-card-subtitle>
            Port Elizabeth, South Africa
          </mat-card-subtitle>
        </section>
        <mat-card-content fxLayout="row wrap" >
          <section fxFlex.lt-sm="100" fxFlex.gt-sm="33" fxFlexOrder="2" fxFlexOrder.lt-sm="1" fxLayout="column" fxLayoutAlign="start center">
            <h3>Enquiries:</h3>
            <div>
              <a href="mailto:info@drdefferary.co.za">info@drdefferary.co.za</a>
            </div>
            <div >
              061 589 1188
            </div>
          </section>
          <section fxFlex.lt-sm="100" fxFlex.gt-sm="33" fxFlexOrder="1" fxFlexOrder.lt-sm="2" fxLayout="column" fxLayoutAlign="start center">
            <h3>
              Accounts:
            </h3>
            <div>
              <a href="mailto:accounts@drdefferary.co.za">accounts@drdefferary.co.za</a>
            </div>
          </section>
          <section fxFlex.lt-sm="100" fxFlex.gt-sm="33" fxFlexOrder="3" fxLayout="column" fxLayoutAlign="start center">
            <h3>
              Consent Forms:
            </h3>
            <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
              <section fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
                <section fxLayoutAlign="center">Adults:</section>
                <section fxLayoutAlign="center">Children:</section>
                <section fxLayoutAlign="center">Teletherapy:</section>
              </section>
              <section fxLayout="column">
                <a href="../assets/Consent-forms-Adults.pdf" download class="iconLink"><mat-icon color="accent">cloud_download</mat-icon></a>
                <a href="../assets/Consent-forms-Children.pdf" download class="iconLink"><mat-icon color="accent">cloud_download</mat-icon></a>
                <a href="../assets/Consent-forms-Teletherapy.pdf" download class="iconLink"><mat-icon color="accent">cloud_download</mat-icon></a>
              </section>
              <section fxLayout="column">
                <a href="../assets/Consent-forms-Adults.pdf" class="iconLink"><mat-icon color="accent">pageview</mat-icon></a>
                <a href="../assets/Consent-forms-Children.pdf" class="iconLink"><mat-icon color="accent">pageview</mat-icon></a>
                <a href="../assets/Consent-forms-Teletherapy.pdf" class="iconLink"><mat-icon color="accent">pageview</mat-icon></a>
              </section>
            </section>



          </section>
        </mat-card-content>
      </mat-card>

    <mat-card class="map-text-card" fxLayout="column">
      <mat-card-content>
        <mat-card-title style="text-align: center">
          Payment Information
        </mat-card-title>
        <h3>
          Medical Aid Clients
        </h3>
        <mat-card-content>
          Most medical aids accepted, claims sent through to medical aid on your behalf.
          <mat-card-subtitle>Ts and Cs apply, please contact the office to determine if your medical aid is accepted.</mat-card-subtitle>

        </mat-card-content>
        <h3>
          Private Clients
        </h3>
        <mat-card-content>
          Forms of payment that are accepted are credit cards, EFT or cash.
          <mat-card-subtitle>Payment due on a per session basis.</mat-card-subtitle>
        </mat-card-content>
        <h3>
          Banking Details
        </h3>
        <mat-card-content fxLayout="row" fxLayoutGap="16px">
          <section>
            <section>Account Holder:</section>
            <section>Bank:</section>
            <section>Account Number:</section>
            <section>Branch Code:</section>
            <section>Reference:</section>
          </section>
          <section>
            <section>Dr Tanya Defferary</section>
            <section>FNB</section>
            <section>622 333 934 54</section>
            <section>211 417</section>
            <section>(Invoice Number)</section>
          </section>
        </mat-card-content>
        <mat-card-subtitle>Payment due on day of consultation.</mat-card-subtitle>
        <mat-card-subtitle>Please email proof of payment to: accounts@drdefferary.co.za</mat-card-subtitle>
      </mat-card-content>
    </mat-card>

        <mat-card class="map-text-card" fxLayout="column">
          <mat-card-content>
            <mat-card-title style="text-align: center">
              Personal Information and Privacy Policies
            </mat-card-title>
            <mat-nav-list>
              <a mat-list-item download
                href="../assets/Consent_to_process_and_retain_personal_information_Adults.pdf">
                Consent to process and retain personal information in accordance with
                the POPI act - Adults </a>
                <a mat-list-item download
                href="../assets/Consent_to_process_and_retain_personal_information_Children.pdf">
                  Consent to process and retain personal information in accordance
                  with the
                  POPI act - Children </a>
                <a mat-list-item download
                  href="../assets/Framework_and_Policy_on_the_Protection_of_Personal_Information_Act_04_of_2013.pdf">
                  Framework and Policy on the Protection of Personal Information Act
                  04 of 2013</a>
            </mat-nav-list>


          </mat-card-content>
        </mat-card>

  </section>
</section>

