<mat-toolbar>
  <div fxHide.gt-sm>
    <button mat-icon-button (click)="onToggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div id="toolbar" fxFlex fxLayoutAlign="center" fxLayoutGap="20px" fxHide.lt-md>
    <button mat-button routerLink="/about-me">About Me</button>
    <button mat-button routerLink="/about-psychotherapy">About Psychotherapy</button>
    <button mat-button routerLink="/how-can-i-help">How Can I Help</button>
    <button mat-button routerLink="/where-to-find-me">Where To Find Me</button>

  </div>
</mat-toolbar>
