import { MatCardModule } from '@angular/material/card';
import {NgModule} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/List';
import { MatSidenavModule } from '@angular/material/Sidenav';
import { MatToolbarModule } from '@angular/material/Toolbar';
import { MatTreeModule } from '@angular/material/Tree';
import { MatRippleModule } from '@angular/material/core';


@NgModule({
  imports: [
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTreeModule,
    MatExpansionModule,
    MatDialogModule,
    MatRippleModule,
    MatTreeModule,
  ],
  exports: [
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTreeModule,
    MatExpansionModule,
    MatDialogModule,
    MatRippleModule,
  ],
})
export class MaterialModule {}
