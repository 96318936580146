import { CovidDialogComponent } from './covid-dialog/covid-dialog.component';
import { BlogComponent } from './blog/blog.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { ServicesComponent } from './services/services/services.component';
import { WorkshopsComponent } from './services/workshops/workshops.component';
import { PsychotherapyComponent } from './services/psychotherapy/psychotherapy.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { AboutPsychotherapyComponent } from './about-psychotherapy/about-psychotherapy.component';
import { ContactComponent } from './contact/contact.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.modual';
import {FlexLayoutModule} from '@angular/flex-layout';
import { AppRoutingModule} from './app-routing.modual';
import { AgmCoreModule } from '@agm/core';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ServicesComponent,
    WorkshopsComponent,
    PsychotherapyComponent,
    AboutMeComponent,
    AboutPsychotherapyComponent,
    ContactComponent,
    MainNavComponent,
    BlogComponent,
    CovidDialogComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDxig7nF_TEpr3R7EC41h0KuaO5L0CHh3Y",
    }),
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
