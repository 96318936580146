import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {fadeAnimation} from './animation/fade.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit{
  @ViewChild('main') mainView: ElementRef;

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  onActivate(event, outlet) {
    outlet.scrollTop = 0;
  }

  ngOnInit(): void {

  }





}
