<div fxLayout="column" fxLayoutAlign="center center">
  <div class="container">
    <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center" >

        <mat-card id="id-card" >
          <img mat-card-image src="../assets/dr-defferary-Dr-Tanya-Defferary.jpg" alt="Dr Tanya Defferary">
          <mat-card-title>
            Dr Tanya Defferary
          </mat-card-title>
          <mat-card-content>
            Counselling Psychologist
          </mat-card-content>
        </mat-card>

        <mat-accordion fxFlexFill>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                About Tanya
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="detail-card-text">
              After qualifying as a Counselling Psychologist in 2008, Dr Tanya Defferary went on
              to graduate with a Doctor of Philosophy (Psychology) Degree from the Nelson
              Mandela Metropolitan University in April 2016.
            </p>
            <p class="detail-card-text">
              She has been in private practice for over 10 years, with a special interest in
              Contextual Therapy. Tanya’s main focus is to help her clients achieve greater
              balance in their lives. In her work with individuals and families, she aims to facilitate
              change and to help people discover their true potential. She practices from her office
              which is situated in the peaceful neighbourhood of Theescombe, in
              Port Elizabeth.
            </p>
            <p class="detail-card-text">
              Over the years, Tanya has worked as a school psychologist, provided therapy to
              children in children’s homes, as well as run her private practice. She also held the
              position of Senior Psychology Lecturer and BPsych Level 4 Practicum Coordinator at
              the Pearson Institute of Higher Education for 7 years. For the past 2 years, Tanya
              has also offered her expertise to develop and facilitate workshops to companies and
              schools across South Africa.
            </p>
          </mat-expansion-panel>
          <!--Published-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Published work
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="mat-title ">Redefining Resilience:  A Process-centered Approach for Interventions with Child Sexual Abuse Survivors</section>
            <section  class="mat-caption">Vol. 6(3), pp. 104 - 112 DOI: 10.13189/ujp.2018.060305 </section>
            <a href="http://www.hrpub.org/journals/article_info.php?aid=7346" target="_blank" class="mat-body-2">Universal Journal of Psychology</a>
            <mat-divider></mat-divider>
            <br>
            <section class="mat-title "> Assessing students’ unwanted early sexual experiences</section>
            <section  class="mat-caption">8(2), 350- 360 </section>
            <a href="https://www.ajol.info/index.php/ajpherd/article/view/78990" target="_blank" class="mat-body-2">African Journal for Physical, Health Education, Recreation and Dance</a>
            <mat-divider></mat-divider>
            <br>
            <section class="mat-title ">Vicarious trauma and burnout</section>
            <section  class="mat-caption">5(4) -- Page32</section>
            <a href="https://www.thepsychologycouch.com/images/Docs/MHM_Volume5_Issue4_Complete.pdf#page=32" target="_blank" class="mat-body-2">SADAG Mental Health Matters</a>

          </mat-expansion-panel>
          <!--Media-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Media
              </mat-panel-title>
            </mat-expansion-panel-header>

              <div class="videos" fxLayout="column">
                <h3 class="mat-headline">Workshop Overview: Exploring Culturally Contextualized Dissociative Parts Through Expressive Arts</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ED3oaI98ooU?si=Fv7Bu16iyWWQTXSa?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Interweave of Polyvagal Theory & Expressive Arts with Dora and Loni</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/zu3Ob9bq3ik?si=7wtpi5cEtQDBPRjp?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Expressive P-Arts: Exploring Dissociative Parts through an Intergenerational Lens</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/I2OlD62FpXA?si=H5BgnAFLDRMTkuxc?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Interview with Linda Homeyer: Sandtray Workshop 2025, South Africa</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/HY9830g_Ebo?si=wf7vixUufOMNqp66?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">A Parent is Born (Drs Defferary & Schultz Inc): meet the directors</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BallNBT8tCw?si=bjJ2Fw8A_jokynjR?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Loni Fagel Expressive Arts Workshop</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Y_KR1LrOwMI?si=bYCwHJhMyBia6gha?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Marshall Lyles Workshop Overview 2024</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/xH-3CNDxiOE?si=wia_DXFegWjWrElb?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">Marshall Lyles Interview November 2023</h3>
                <div class="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/mGLhTYCEAik?si=ggjO-WvC_oQ9TMHE?rel=0"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">SABC News: Gender-based Violence</h3>
                <div class="video-container">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/lAB2UPsZXmY?rel=0"
                          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">SABC News: Nelson Mandela Bay female residents live in fear</h3>
                <div class="video-container">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/kc1X_kUuyAM?rel=0&amp;start=78"
                          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                <mat-divider></mat-divider>
                <h3 class="mat-headline">SABC News: Men also fall victim to rape</h3>
                <div class="video-container">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/BwsvtTht4y8?rel=0&amp;start=160"
                          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>


              </div>
              <!--<h3 class="mat-headline">Kingfisher FM Podcast:</h3>-->
              <!--<vg-player style="height: 50px;" (onPlayerReady)="onPlayerReady($event)">-->
                <!--<vg-controls>-->
                  <!--<vg-play-pause></vg-play-pause>-->
                  <!--<vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>-->
                  <!--<vg-scrub-bar>-->
                    <!--<vg-scrub-bar-current-time></vg-scrub-bar-current-time>-->
                    <!--<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>-->
                  <!--</vg-scrub-bar>-->
                  <!--<vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>-->
                <!--</vg-controls>-->
                <!--<audio #media [vgMedia]="media" [src]="currentItem.src" id="myAudio" preload="auto" crossorigin>-->
                <!--</audio>-->
              <!--</vg-player>-->
            <!--<ul>-->
              <!--<li *ngFor="let item of playlist; let $index = index"-->
                  <!--(click)="onClickPlaylistItem(item, $index)"-->
                  <!--[class.selected]="item === currentItem">-->
                <!--{{ item.title }}-->
              <!--</li>-->
            <!--</ul>-->
          </mat-expansion-panel>
        </mat-accordion>


    </div>
  </div>
</div>
<img mat-card-image src="../assets/dr-defferary--what-is-psychotherapy.jpg" style="display: none">
<img mat-card-image src="../assets/dr-defferary-counselling.jpg" style="display: none">
<img mat-card-image src="../assets/dr-defferary-workshops.jpg" style="display: none">
<img mat-card-image src="../assets/dr-defferary-workshops2.jpg" style="display: none">
<img mat-card-image src="../assets/dr-defferary-counselling2.jpg" style="display: none">


