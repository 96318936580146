import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DialogStateService} from '../dialog-state.service';

@Component({
  selector: 'app-covid-dialog',
  templateUrl: './covid-dialog.component.html',
  styleUrls: ['./covid-dialog.component.css']
})
export class CovidDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CovidDialogComponent>, private router: Router, private dialogState: DialogStateService) { }

  ngOnInit() {
    this.dialogState.dialogEvent();
  }

  onDialogClose() {
    this.dialogRef.close();
  }

  onBlog() {
    this.dialogRef.close();
    this.router.navigateByUrl('/blog');
  }
}
